import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";

const Data = () => {
  const [data, setData] = useState();

  useEffect(() => {
    displayData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchData = async () => {
    const token = await fetchJWT();
    try {
      console.log(token);
      const result = await axios.get("https://39gzwduh9j.execute-api.ap-southeast-2.amazonaws.com/dev/var", {
        headers: {
          Authorization: token
        }
      });
      let msg = JSON.parse(result.data.body).message;
      console.log(msg);
      setData(msg);
    } catch (error) {
      alert('Could not fetch auctions! Check console for more details.');
      console.error(error);
    }
  };

  const fetchJWT = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  };

  const displayData = () => {
    if (data !== undefined) {
      return data.length > 0 && <div>{data}</div>;
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <button onClick={fetchData}>Fetch Data</button>
      {displayData()}
    </div>
  );
};

export default Data;
