import './App.css';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import Data from './components/Data';
import Plot from './components/Plot';

Amplify.configure({
  Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
  }
})

function App() {
  return (
      <Authenticator>
          {({ signOut, user }) => (
              <div>
                  <Plot/>
                  <p>Welcome {user.username}</p>
                  <Data/>
                  <button onClick={signOut}>Sign out</button>
                 
              </div>
          )}
      </Authenticator>
  );
}

export default App;